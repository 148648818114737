import { computed } from 'vue'
import { useStore } from 'skid-composables'

export default (promoterCampaign, promoterCampaignPeriod) => {
  const store = useStore()

  const campaignImages = computed(() => {
    return store.getters['promoterCampaignImages/all'].filter((image) => {
      return image.campaign_id === parseInt(promoterCampaign.id)
    })
  })

  const campaignCoverImage = computed(() => {
    return campaignImages.value.find((image) => {
      return image.is_cover
    })
  })

  const promoterEventProductShips = computed(() => {
    return store.getters['promoterEventProductShips/all'].filter(
      (promoterEventProductShip) => {
        return (
          promoterEventProductShip.campaign_id === parseInt(promoterCampaign.id)
        )
      }
    )
  })

  const products = computed(() => {
    return promoterEventProductShips.value.map((ship) => {
      return store.getters['products/find'](ship.product_id)
    })
  })

  const images = computed(() => {
    if (campaignImages.value.length > 0) {
      if (!promoterCampaignPeriod) {
        return campaignImages.value.map((image) => {
          return image
        })
      } else {
        return campaignImages.value
          .sort((a, b) => {
            return a.period_id ? 1 : 0 - b.period_id ? 1 : 0
          })
          .map((image) => {
            if (
              image.period_id &&
              image.period_id != parseInt(promoterCampaignPeriod.id)
            )
              return false

            return image
          })
          .filter(Boolean)
      }
    } else if (products.value.length > 0) {
      return products.value.map((product) => {
        return {
          image: {
            url: product.cover.url
          }
        }
      })
    } else {
      return [
        {
          image: {
            url: randomImage()
            // url: 'https://via.placeholder.com/1000x200?text=No+Image'
          }
        }
      ]
    }
  })

  function randomImage() {
    return `https://pub-b9fe616a8af8498d8a50230585d16b41.r2.dev/kolnet-${
      promoterCampaign.id % 100
    }.jpg`
  }

  return {
    images
  }
}
